<template>
    <div class="page-modal">
        <!-- 弹出框 -->
        <el-dialog v-model="showModal" width="80%" center>
            <el-descriptions :column="2" border>
                <el-descriptions-item label="交易流水号">{{ currentOrder.trade_no }}</el-descriptions-item>
                <el-descriptions-item label="渠道交易号">{{ currentOrder.channel_trade_no }}</el-descriptions-item>
                <el-descriptions-item label="商户订单号">{{ currentOrder.out_trade_no }}</el-descriptions-item>
                <el-descriptions-item label="商户名称">{{ currentOrder.merchant_name }}</el-descriptions-item>
                <el-descriptions-item label="商户编号">
                    {{ currentOrder.merchant_no }}
                </el-descriptions-item>
                <el-descriptions-item label="门店名称">{{ currentOrder.store_name }}</el-descriptions-item>
                <el-descriptions-item label="代理商名称">{{ currentOrder.agent_name }}</el-descriptions-item>
                <el-descriptions-item label="支付设备">{{ currentOrder.serial_number }}</el-descriptions-item>
                <el-descriptions-item label="交易费率">{{ currentOrder.rate }}</el-descriptions-item>
                <el-descriptions-item label="手续费">{{ currentOrder.fee_amount }}</el-descriptions-item>
                <el-descriptions-item label="订单金额(元)">{{ currentOrder.total_amount }}</el-descriptions-item>
                <el-descriptions-item label="支付金额(元)">{{ currentOrder.pay_amount }}</el-descriptions-item>
                <el-descriptions-item label="优惠金额(元)">{{ currentOrder.discount_amount }}</el-descriptions-item>
                <el-descriptions-item label="商户实收金额(元)">{{ currentOrder.receipt_amount }}</el-descriptions-item>
                <el-descriptions-item label="退款金额(元)">{{ currentOrder.refund_amount }}</el-descriptions-item>
                <el-descriptions-item label="支付方式">{{ currentOrder.payment_method }}</el-descriptions-item>
                <el-descriptions-item label="订单状态">
                    <el-tag :type="getStatusTagType(currentOrder.status_format)">
                        {{ currentOrder.status_format }}
                    </el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="是否结算">
                    <el-tag v-if="currentOrder.is_settlement == 1" type="success" size="small">是</el-tag>
                    <el-tag v-else type="danger" size="small">否</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="创建时间">{{ currentOrder.created_at }}</el-descriptions-item>
                <el-descriptions-item label="备注">{{ currentOrder.remarks }}</el-descriptions-item>
            </el-descriptions>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="showModal = false">关闭</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, defineExpose } from 'vue';

// 控制弹出框的显示与隐藏
const showModal = ref(false);

// 当前选中的订单数据
const currentOrder = ref(null);

// 打开弹出框并设置当前订单数据
const openModal = (order) => {
    showModal.value = true;
    currentOrder.value = order

};

// 根据订单状态获取标签类型
const getStatusTagType = (status) => {
    switch (status) {
        case '支付成功': return 'success'; // 支付成功
        case '支付失败': return 'danger'; // 支付失败
        default: return 'info';
    }
};



// 暴露 openModal 方法，供父组件调用
defineExpose({ openModal });
</script>

<style lang="less" scoped>
.page-modal {
    text-align: center;
    padding: 20px;

    .statuscheck {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
}

.dialog-footer {
    text-align: center;
}
</style>